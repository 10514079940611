import { REPORT_API_URL } from "../config/apiConfig";
import { SalesReportResponse } from "../Models/SalesReportResponse";
import { apiService } from "./apiService";

const reportService = {
    getSalesReport: async (startDate?: string, endDate?: string): Promise<SalesReportResponse[]> => {
        const queryParams: Record<string, string> = {};
        if (startDate) {
            queryParams.startDate = startDate;
        }        
        if (endDate) {
            queryParams.endDate = endDate;
        }
        return await apiService(REPORT_API_URL, {
            method: 'GET',
            excludeAppId: false,
            queryParams: queryParams
        });
    }
}

export default reportService;
